import { ConnectWallet } from "@thirdweb-dev/react";

export default function Header() {
    return (
        <header className="transparent">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex sm-pt10">
                            <div className="de-flex-col">
                                <div className="de-flex-col">
                                    {/* logo begin */}
                                    <div id="logo">
                                        <a href="/">
                                            <img
                                                style={{ width: "100px" }}
                                                alt="" src="https://ucarecdn.com/2b0fb524-b3bc-4666-8449-41d5675a1f02/" />
                                        </a>
                                    </div>
                                    {/* logo close */}
                                </div>

                            </div>
                            <div className="de-flex-col header-col-mid">
                                {/* mainmenu begin */}
                                <ul id="mainmenu">
                                    <li style={
                                            {
                                                paddingRight: "20px",
                                            }}>
                                        <a href="/">Home<span /></a>
                                        <ul>


                                        </ul>
                                    </li>
                                    



                                </ul>
                                <ConnectWallet />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    );
}
