import { ConnectWallet } from "@thirdweb-dev/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Home";
import Mintpage from "./Pages/Mint";
export default function Home() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage/>} />
          <Route path="/mint" element={<Mintpage/>} />
        </Routes>
      </Router>
    </>
  );
}
