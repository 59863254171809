import { ConnectWallet, useAddress, useNetwork } from "@thirdweb-dev/react";
import { useNetworkMismatch, ChainId } from "@thirdweb-dev/react";
import Swal from "sweetalert2";

import Header from "../common/Header";
import { useState } from "react";
import { Widget } from "@uploadcare/react-widget";

export default function Homepage() {
  const [data, setData] = useState([]);
  const wallet = useAddress();
  const [, switchNetwork] = useNetwork();
  const [loading, setLoading] = useState(false);
  const isMismatched = useNetworkMismatch();

  async function SubmitForm() {
    console.log("data", data);
    if (!wallet) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please connect wallet!",
      });
      return;
    }
    if (!data.image || !data.name || !data.description) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill all the fields!",
      });

      return;
    }

    setLoading(true);
    Swal.fire({
      icon: "success",
      title: "Success...",
      text: "Your NFT is being minted!",
    });
    const formData = new FormData();
    formData.append("image_url", data.image);
    formData.append("nft_name", data.name);
    formData.append("description", data.description);
    formData.append("mintto", wallet);
    const response = await fetch("https://supermint.tech/mint", {
      method: "POST",
      body: formData,
    });
    const result = await response.json();
    Swal.fire({
      icon: "success",
      title: "Success...",
      text: `NFT minted successfully - https://mumbai.polygonscan.com/tx/${result.hash}`,
    });

    console.log(result);
    setLoading(false);
  }
  return (
    <>
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top" />
        {/* section begin */}
        <section id="subheader" className="text-light">
          <div className="center-y relative text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Super Mint Demo</h1>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </section>
        {/* section close */}
        {/* section begin */}
        <section aria-label="section">
          <div className="container">
            <div className="row wow fadeIn">
              <div className="col-lg-7 offset-lg-1">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    SubmitForm();
                  }}
                  id="form-create-item"
                  className="form-border"
                >
                  <div className="field-set">
                    <h5>Upload file</h5>
                    <div className="d-create-file">
                      <p id="file_name">
                        PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                      </p>
                      <Widget
                        imagesOnly={true}
                        onChange={(file) => {
                          setData({ ...data, image: file.cdnUrl });
                        }}
                        publicKey="ca1d60d53a06b4f464f7"
                        clearable
                      />
                    </div>
                    <div className="spacer-40" />
                    <h5>Chain</h5>
                    <div className="de_tab tab_methods">
                      <ul className="de_nav">
                        <li className="active">
                          <span>
                            <i className="fa fa-tag" />
                            Polygon
                          </span>
                        </li>
                      </ul>
                      <div className="de_tab_content">
                        <div id="tab_opt_1">
                          <h5>Name</h5>

                          <input
                            type="text"
                            name="item_price"
                            id="item_price"
                            className="form-control"
                            placeholder="Enter the name of the NFT"
                            onChange={(e) => {
                              setData({ ...data, name: e.target.value });
                            }}
                          />
                          <div className="spacer-20" />
                          <h5>Description</h5>
                          <input
                            type="text"
                            name="item_title"
                            id="item_title"
                            className="form-control"
                            placeholder="Enter the description of the NFT"
                            onChange={(e) => {
                              setData({ ...data, description: e.target.value });
                            }}
                          />
                          <div className="spacer-20" />

                          <button
                            disabled={loading}
                            type="button"
                            id="submit"
                            className="btn-main"
                            defaultValue="Mint"
                            onClick={(e) => {
                              e.preventDefault();
                              SubmitForm();
                            }}
                            style={{ width: "100%", padding: "15px" }}
                          >
                            Mint NFT
                          </button>
                          <div className="spacer-single" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-3 col-sm-6 col-xs-12">
                <h5>Preview item</h5>
                <div className="nft__item">
                  <div className="author_list_pp"></div>
                  <div className="nft__item_wrap">
                    <a href="#">
                      <img
                        src={data.image}
                        id="get_file_2"
                        className="lazy nft__item_preview"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="nft__item_info">
                    <a href="#">
                      <h4>{data.name}</h4>
                    </a>
                    <div className="nft__item_click">{data.description}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
