import {
    useActiveClaimConditionForWallet,
    useAddress,
    useClaimConditions,
    useClaimedNFTSupply,
    useClaimerProofs,
    useClaimIneligibilityReasons,
    useContract,
    useContractMetadata,
    useUnclaimedNFTSupply,
    Web3Button,
    useMetadata,
} from "@thirdweb-dev/react";
import { BigNumber, utils } from "ethers";
import { useMemo, useState } from "react";
import { parseIneligibility } from "../utils/util.ts";
import Header from "../common/Header";
// Put Your NFT Drop Contract address from the dashboard here
const myNftDropContractAddress = "0x6028fCFF014dE31511a353521Fd03c21cC0B05C9";

export default function Mintpage() {
    const { contract: nftDrop } = useContract(myNftDropContractAddress);
    const { data: metadata } = useMetadata(nftDrop);
    console.log("nftDrop", metadata);

    const address = useAddress();
    const [quantity, setQuantity] = useState(1);

    const { data: contractMetadata } = useContractMetadata(nftDrop);

    const claimConditions = useClaimConditions(nftDrop);

    const activeClaimCondition = useActiveClaimConditionForWallet(
        nftDrop,
        address || ""
    );
    const claimerProofs = useClaimerProofs(nftDrop, address || "");
    const claimIneligibilityReasons = useClaimIneligibilityReasons(nftDrop, {
        quantity,
        walletAddress: address || "",
    });
    const unclaimedSupply = useUnclaimedNFTSupply(nftDrop);
    const claimedSupply = useClaimedNFTSupply(nftDrop);

    const numberClaimed = useMemo(() => {
        return BigNumber.from(claimedSupply.data || 0).toString();
    }, [claimedSupply]);

    const numberTotal = useMemo(() => {
        return BigNumber.from(claimedSupply.data || 0)
            .add(BigNumber.from(unclaimedSupply.data || 0))
            .toString();
    }, [claimedSupply.data, unclaimedSupply.data]);

    const priceToMint = useMemo(() => {
        const bnPrice = BigNumber.from(
            activeClaimCondition.data?.currencyMetadata.value || 0
        );
        return `${utils.formatUnits(
            bnPrice.mul(quantity).toString(),
            activeClaimCondition.data?.currencyMetadata.decimals || 18
        )} ${activeClaimCondition.data?.currencyMetadata.symbol}`;
    }, [
        activeClaimCondition.data?.currencyMetadata.decimals,
        activeClaimCondition.data?.currencyMetadata.symbol,
        activeClaimCondition.data?.currencyMetadata.value,
        quantity,
    ]);

    const maxClaimable = useMemo(() => {
        let bnMaxClaimable;
        try {
            bnMaxClaimable = BigNumber.from(
                activeClaimCondition.data?.maxClaimableSupply || 0
            );
        } catch (e) {
            bnMaxClaimable = BigNumber.from(1_000_000);
        }

        let perTransactionClaimable;
        try {
            perTransactionClaimable = BigNumber.from(
                activeClaimCondition.data?.maxClaimablePerWallet || 0
            );
        } catch (e) {
            perTransactionClaimable = BigNumber.from(1_000_000);
        }

        if (perTransactionClaimable.lte(bnMaxClaimable)) {
            bnMaxClaimable = perTransactionClaimable;
        }

        const snapshotClaimable = claimerProofs.data?.maxClaimable;

        if (snapshotClaimable) {
            if (snapshotClaimable === "0") {
                // allowed unlimited for the snapshot
                bnMaxClaimable = BigNumber.from(1_000_000);
            } else {
                try {
                    bnMaxClaimable = BigNumber.from(snapshotClaimable);
                } catch (e) {
                    // fall back to default case
                }
            }
        }

        const maxAvailable = BigNumber.from(unclaimedSupply.data || 0);

        let max;
        if (maxAvailable.lt(bnMaxClaimable)) {
            max = maxAvailable;
        } else {
            max = bnMaxClaimable;
        }

        if (max.gte(1_000_000)) {
            return 1_000_000;
        }
        return max.toNumber();
    }, [
        claimerProofs.data?.maxClaimable,
        unclaimedSupply.data,
        activeClaimCondition.data?.maxClaimableSupply,
        activeClaimCondition.data?.maxClaimablePerWallet,
    ]);

    const isSoldOut = useMemo(() => {
        try {
            return (
                (activeClaimCondition.isSuccess &&
                    BigNumber.from(activeClaimCondition.data?.availableSupply || 0).lte(
                        0
                    )) ||
                numberClaimed === numberTotal
            );
        } catch (e) {
            return false;
        }
    }, [
        activeClaimCondition.data?.availableSupply,
        activeClaimCondition.isSuccess,
        numberClaimed,
        numberTotal,
    ]);

    console.log("claimIneligibilityReasons", claimIneligibilityReasons.data);

    const canClaim = useMemo(() => {
        return (
            activeClaimCondition.isSuccess &&
            claimIneligibilityReasons.isSuccess &&
            claimIneligibilityReasons.data?.length === 0 &&
            !isSoldOut
        );
    }, [
        activeClaimCondition.isSuccess,
        claimIneligibilityReasons.data?.length,
        claimIneligibilityReasons.isSuccess,
        isSoldOut,
    ]);

    const isLoading = useMemo(() => {
        return (
            activeClaimCondition.isLoading ||
            unclaimedSupply.isLoading ||
            claimedSupply.isLoading ||
            !nftDrop
        );
    }, [
        activeClaimCondition.isLoading,
        nftDrop,
        claimedSupply.isLoading,
        unclaimedSupply.isLoading,
    ]);

    const buttonLoading = useMemo(
        () => isLoading || claimIneligibilityReasons.isLoading,
        [claimIneligibilityReasons.isLoading, isLoading]
    );
    const buttonText = useMemo(() => {
        if (isSoldOut) {
            return "Sold Out";
        }

        if (canClaim) {
            const pricePerToken = BigNumber.from(
                activeClaimCondition.data?.currencyMetadata.value || 0
            );
            if (pricePerToken.eq(0)) {
                return "Mint (Free)";
            }
            return `Mint (${priceToMint})`;
        }
        if (claimIneligibilityReasons.data?.length) {
            return parseIneligibility(claimIneligibilityReasons.data, quantity);
        }
        if (buttonLoading) {
            return "Checking eligibility...";
        }

        return "Claiming not available";
    }, [
        isSoldOut,
        canClaim,
        claimIneligibilityReasons.data,
        buttonLoading,
        activeClaimCondition.data?.currencyMetadata.value,
        priceToMint,
        quantity,
    ]);
    console.log("buttonText", buttonText);
    return (
        <>
            <Header />
            <div>
                <div className="no-bottom no-top" id="content">
                    <div id="top" />
                    <section
                        id="nft-item-details"
                        aria-label="section"
                        className="sm-mt-0"
                    >
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-md-6 text-center">
                                    <div className="nft-image-wrapper">
                                        <img
                                            src={metadata && metadata.image}
                                            className="image-autosize img-fluid img-rounded mb-sm-30"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="item_info">

                                        <h2>{metadata && metadata.name}</h2>
                                        <div className="item_info_counts">
                                            <div className="item_info_type">
                                                <i className="fa fa-image" />
                                                Art
                                            </div>
                                            <div className="item_info_views">
                                                <i className="fa fa-eye" />
                                                250
                                            </div>
                                            <div className="item_info_like">
                                                <i className="fa fa-heart" />
                                                18
                                            </div>
                                        </div>
                                        <p>{metadata && metadata.description}</p>
                                        <div className="d-flex flex-row">
                                            <div className="mr40">
                                                <h6>Creator</h6>
                                                <div className="">
                                                    <div className="">

                                                    </div>
                                                    <div className="bold">
                                                        <a >Sagar</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="spacer-10" />
                                        <div className="de_tab tab_simple">

                                            <div className="de_tab_content">
                                                <div className="tab-1">
                                                    <h6>Supply</h6>
                                                    <div className="item_author">
                                                        <div className="">
                                                            <b>{numberClaimed}</b>
                                                            {" / "}
                                                            {numberTotal}
                                                        </div>
                                                    </div>
                                                    <div className="spacer-40" />
                                                </div>
                                            </div>
                                            <div className="spacer-10" />
                                            <h6>Price</h6>
                                            <div className="nft-item-price">
                                                <span>{priceToMint === 'undefined' ? 'Loading' : priceToMint} </span>
                                            </div>

                                            {isSoldOut ? (
                                                <div>
                                                    <h2>Sold Out</h2>
                                                </div>
                                            ) : (
                                                <>
                                                    <a
                                                        href="#"
                                                        className="btn-main btn-lg"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#buy_now"
                                                    >
                                                        Buy Now
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="btn-main btn-lg"
                                                        style={
                                                            {
                                                                margin: "10px",
                                                            }
                                                        }
                                                    >
                                                        Buy With Credit Card
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* content close */}
                {/* buy now */}
                <div
                    className="modal fade"
                    id="buy_now"
                    tabIndex={-1}
                    aria-labelledby="buy_now"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered de-modal">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                            <div className="modal-body">
                                <div className="p-3 form-border">
                                    <h3>Checkout</h3>
                                    You are about to purchase {metadata && metadata.name} for{" "} {priceToMint}{" "}/Each NFT
                                    <div className="spacer-single" />
                                    <h6>
                                        Enter quantity.{" "}

                                    </h6>
                                    <input
                                        type="number"
                                        min={1}
                                        max={maxClaimable}
                                        name="buy_now_qty"
                                        id="buy_now_qty"
                                        className="form-control"
                                        value={quantity}
                                        onChange={(e) => {
                                            if (e.target.value === "" || e.target.value === "0") {
                                                e.target.value = 1;
                                            }
                                            setQuantity(parseInt(e.target.value))
                                        }}
                                    />


                                    <div className="spacer-single" />
                                    <Web3Button
                                        contractAddress={nftDrop?.getAddress() || ""}
                                        action={(cntr) => cntr.erc721.claim(quantity)}
                                        isDisabled={!canClaim || buttonLoading}
                                        onError={(err) => {
                                            console.error(err);
                                            alert("Error claiming NFTs", err);
                                        }}
                                        onSuccess={() => {
                                            setQuantity(1);
                                            alert("Successfully claimed NFTs");
                                        }}
                                    >
                                        {buttonLoading ? "Loading..." : buttonText}
                                    </Web3Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}
